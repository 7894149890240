@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.company-name {
  font-family: 'Cinzel', serif;
  font-weight: bold;
}

.home-names{
  font-family: 'Archivo', sans-serif;
  font-weight: 500;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bold {
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.italic {
  font-style: italic;
}

.bg-cover {
  background-size: cover;
}

.bg-center {
  background-position: center;
}

.zoom-animation {
  animation: zoom 5s infinite;
}

.zoom-container {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.zoom-image {
  background-size: cover;
  background-position: center;
  animation: zoom 10s infinite;
}

@keyframes zoom {
  0% {
    transform: scale(1);
    transform-origin: center center;
  }
  50% {
    transform: scale(1.05); /* Increased zoom */
    transform-origin: calc(var(--random-x) * 100%) calc(var(--random-y) * 100%);
  }
  100% {
    transform: scale(1);
    transform-origin: center center;
  }
}

@keyframes random {
  0% {
    transform-origin: center center;
  }
  100% {
    transform-origin: calc(10% + 80% * var(--random-x))
      calc(10% + 80% * var(--random-y));
  }
}

.link-underline::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0%;
  height: 5%;
  background-color: #b7410e;
  transition: width 0.3s ease;
}

.link-underline:hover::after {
  width: 100%;
}

.link-active::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 5%;
  background-color: #b7410e;
}

.modal{
  width: fit-content !important;
}